import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import Toggle from "@vueform/toggle";
import { notification } from "@/core/helpers/custom";
import { newAddress, showAddress, updateAddress } from "@/api/AddressApi";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
export default defineComponent({
    name: "address-form",
    components: {
        Form: Form,
        Toggle: Toggle,
    },
    setup: function () {
        var _this = this;
        var _a;
        var center = ref({ lat: 11.578956353229527, lng: 104.88878130912781 });
        var route = useRoute();
        var router = useRouter();
        var addressDetails = ref({});
        var t = useI18n().t;
        var currentSavedId = ref(null);
        // const formLoading = ref(false);
        var loading = ref({
            form: false,
            submit: false,
        });
        var show = ref({
            map: false,
        });
        var googleMapKey = (_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.VUE_APP_GOOGEL_MAP_KEY;
        var isEdit = computed(function () {
            return route.params.addressId !== undefined;
        });
        var editId = computed(function () {
            return route.params.addressId;
        });
        var marker = ref({
            position: center,
        });
        var centerModal = ref({
            lat: 11.578956353229527,
            lng: 104.88878130912781,
        });
        var markerModal = ref({
            position: centerModal,
        });
        //Create form validation object
        var addressValidate = Yup.object().shape({
            name: Yup.string().required("Required").label("Name"),
            // firstname: Yup.string().required().label("First Name"),
            // lastname: Yup.string().required().label("Last Name"),
            // mobile: Yup.string().required("Required").label("Mobile"),
            // email: Yup.string().email().label("Email"),
            address: Yup.string().required("Required").label("Address"),
        });
        var onSubmitAddress = function (_value, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(_this, void 0, void 0, function () {
                var message;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            loading.value.submit = true;
                            message = "";
                            if (!isEdit.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, updateAddress(addressDetails.value).then(function (res) {
                                    message = res.data.message;
                                })];
                        case 1:
                            _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, newAddress(addressDetails.value).then(function (res) {
                                var _a;
                                message = res.data.message;
                                currentSavedId.value = (_a = res.data) === null || _a === void 0 ? void 0 : _a.current_save_id;
                            })];
                        case 3:
                            _b.sent();
                            resetForm();
                            _b.label = 4;
                        case 4:
                            // router.push({ name: "account-address-listing" });
                            loading.value.submit = false;
                            notification({
                                type: "success",
                                message: message !== null && message !== void 0 ? message : "Success",
                            });
                            return [2 /*return*/];
                    }
                });
            });
        };
        var bindingEditData = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.form = true;
                        return [4 /*yield*/, showAddress({
                                addressId: editId.value,
                            })];
                    case 1:
                        res = _a.sent();
                        addressDetails.value = res.data.data;
                        center.value = {
                            lat: addressDetails.value.lat,
                            lng: addressDetails.value.lng,
                        };
                        loading.value.form = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var moveMarker = function (event) {
            addressDetails.value.lat = event.latLng.lat();
            addressDetails.value.lng = event.latLng.lng();
            center.value = {
                lat: addressDetails.value.lat,
                lng: addressDetails.value.lng,
            };
            reverseAddress(center);
        };
        var moveMarkerModal = function (event) {
            centerModal.value.lat = event.latLng.lat();
            centerModal.value.lng = event.latLng.lng();
            // reverAddressHandler();
        };
        var setPlace = function (event) {
            markerModal.value.position = {
                lat: event.geometry.location.lat(),
                lng: event.geometry.location.lng(),
            };
            addressDetails.value.lat = center.value.lat;
            addressDetails.value.lng = center.value.lng;
            // reverAddressHandler();
        };
        // function reverAddressHandler() {
        //   reverseAddress({
        //     lat: addressDetails.value.lat,
        //     lng: addressDetails.value.lng,
        //   }).then((res) => {
        //     addressDetails.value.address = res[0].formatted_address;
        //   });
        // }
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    marker.value.position = center.value = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    addressDetails.value.lat = position.coords.latitude;
                    addressDetails.value.lng = position.coords.longitude;
                    reverseAddress(centerModal);
                }, function (error) {
                    console.log("%cForm.vue line:335 error", "color: #007acc;", error);
                });
            }
            else {
                console.log("Geolocation is not supported by this browser.");
            }
        }
        var showMap = function () {
            show.value.map = !show.value.map;
        };
        var submitModal = function () {
            marker.value.position = center.value = {
                lat: centerModal.value.lat,
                lng: centerModal.value.lng,
            };
            addressDetails.value.lat = centerModal.value.lat;
            addressDetails.value.lng = centerModal.value.lng;
            reverseAddress(centerModal);
            closeModal();
        };
        var isShow = ref(false);
        function showModal() {
            isShow.value = true;
        }
        function closeModal() {
            isShow.value = false;
        }
        var reverseAddress = function (center) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: center.value }, function (res) {
                addressDetails.value.address = res[0].formatted_address;
            });
        };
        onMounted(function () {
            setCurrentPageBreadcrumbs("Settings", ["Account"]);
            if (isEdit.value) {
                bindingEditData();
            }
            else {
                addressDetails.value.is_default = true;
                getLocation();
            }
        });
        return {
            onSubmitAddress: onSubmitAddress,
            addressValidate: addressValidate,
            addressDetails: addressDetails,
            route: route,
            center: center,
            googleMapKey: googleMapKey,
            moveMarker: moveMarker,
            marker: marker,
            setPlace: setPlace,
            loading: loading,
            isEdit: isEdit,
            show: show,
            showMap: showMap,
            moveMarkerModal: moveMarkerModal,
            submitModal: submitModal,
            markerModal: markerModal,
            centerModal: centerModal,
            showModal: showModal,
            closeModal: closeModal,
            isShow: isShow,
            router: router,
            currentSavedId: currentSavedId,
        };
    },
});
